import { FormError } from 'components/atoms/FormError';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ShipmentInput } from '@magicship/utils/shipment';
import { UnitSelect } from './UnitSelect';
import { WeightUnit } from 'src/API';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInput = styled(Input)`
    width: 86px;
`;

type Props = {
    index: number;
    contrast?: boolean;
};

export const WeightField: React.FunctionComponent<Props> = ({ index, contrast = true }: Props) => {
    const { t } = useTranslation(['shipping', 'common']);
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<ShipmentInput>();
    const error = errors?.boxes?.[index]?.weight?.value;
    const unitError = errors?.boxes?.[index]?.weight?.unit;
    const valueErrorMessage =
        error?.message ||
        (error ? t('Weight is required') : '') ||
        unitError?.message ||
        (unitError ? t('Weight unit is required') : '');
    const bg = contrast ? 'contrast' : 'default';
    return (
        <div>
            <Container>
                <StyledInput
                    className="data-hj-allow"
                    error={Boolean(error)}
                    bg={bg}
                    placeholder={'0'}
                    type="number"
                    step="any"
                    min={0}
                    {...register(`boxes.${index}.weight.value`, {
                        required: true,
                        valueAsNumber: true,
                        validate: {
                            positive: (value) => parseFloat(value) > 0,
                        },
                    })}
                />
                <Controller
                    render={({ field }) => (
                        <UnitSelect
                            name={field.name}
                            values={Object.values(WeightUnit)}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                    control={control}
                    name={`boxes.${index}.weight.unit`}
                    rules={{ required: t<string>('Weight unit is required') }}
                />
            </Container>
            {valueErrorMessage && <FormError>{valueErrorMessage}</FormError>}
        </div>
    );
};
