import { useCallback } from 'react';
import styled from 'styled-components';
import hexRgb from 'hex-rgb';

const RadioIcon = styled.span<{ kind: 'primary' | 'secondary'; fill: 'contrast' | 'default' }>`
    position: relative;
    width: 20px;
    height: 20px;
    background: ${({ theme, kind, fill }) =>
        fill === 'contrast'
            ? theme.text.contrast
            : hexRgb(kind === 'primary' ? theme.colors.primary : theme.colors.secondary, {
                  format: 'css',
                  alpha: 0.2,
              })};
    border-radius: 50%;
    display: inline-block;

    &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50px;
        opacity: 0;
        transition: opacity 0.3s ease;
        background: ${({ theme, kind }) => (kind === 'primary' ? theme.colors.primary : theme.colors.secondary)};
    }
`;

const RadioLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    input {
        display: none;

        &:checked {
            & ~ ${RadioIcon} {
                &::before {
                    opacity: 0.7;
                }
            }
        }
    }
`;

const RadioText = styled.span`
    margin-left: 5px;
`;

type Props<T> = {
    name: string;
    value: T;
    onChange: (value: T) => void;
    isSelected: boolean;
    label?: string;
    kind?: 'primary' | 'secondary';
    fill?: 'contrast' | 'default';
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
};

export const RadioInput = <T extends string>({
    className,
    style,
    label,
    value,
    name,
    onChange,
    isSelected,
    kind = 'primary',
    fill = 'default',
    disabled,
}: Props<T>): React.ReactElement => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value as T);
        },
        [onChange]
    );
    return (
        <RadioLabel className={className} style={style}>
            <input
                name={name}
                type="radio"
                checked={isSelected}
                aria-checked={isSelected}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            />
            <RadioIcon fill={fill} kind={kind} />
            {label && <RadioText>{label}</RadioText>}
        </RadioLabel>
    );
};
