import hexRgb from 'hex-rgb';
import React, { useCallback, useMemo } from 'react';
import { default as SelectRaw, components, DropdownIndicatorProps, GroupBase, CSSObjectWithLabel } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { useTheme } from 'styled-components';

const selectTheme = {
    borderRadius: 0,
};

function DropdownIndicator<
    OptionType,
    IsMulti extends boolean,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: DropdownIndicatorProps<OptionType, IsMulti, GroupType>): React.ReactElement {
    return (
        <components.DropdownIndicator<OptionType, IsMulti, GroupType> {...props}>
            <svg width="21.192" height="12.01">
                <path
                    d="m20.485.708-9.889 9.889L.707.708"
                    fill="none"
                    stroke="#979797"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
            </svg>
        </components.DropdownIndicator>
    );
}

type Props<Option> = { error?: boolean; contrast?: boolean; inputRef?: React.Ref<unknown> } & StateManagerProps<
    Option,
    false
>;

export const Select = <OptionType,>({ error, contrast, inputRef, ...props }: Props<OptionType>): React.ReactElement => {
    const theme = useTheme();

    const currentTheme = useCallback((theme) => ({ ...theme, ...selectTheme }), []);

    const selectStyles = useMemo(
        () => ({
            control: (p: CSSObjectWithLabel) => ({
                ...p,
                border: 'none',
                background: hexRgb(
                    error ? theme.colors.danger : contrast ? theme.text.contrast : theme.colors.primary,
                    {
                        alpha: contrast ? 1 : 0.2,
                        format: 'css',
                    }
                ),
                height: '44px',
                paddingLeft: '5px',
                paddingRight: '5px',
            }),
            placeholder: (p: CSSObjectWithLabel) => ({
                ...p,
                color: theme.text.placeholder,
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
        }),
        [theme, error, contrast]
    );

    const currentComponents = useMemo(
        () => ({
            DropdownIndicator,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Input: (props: any) => <components.Input {...props} inputClassName="data-hj-allow" />,
        }),
        []
    );

    const ref = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (node: any) => {
            if (inputRef && node) {
                if (typeof inputRef === 'function') {
                    inputRef(node.inputRef);
                }
            }
        },
        [inputRef]
    );
    const portalTarget = typeof window !== 'undefined' ? document.body : null;

    return (
        <SelectRaw
            ref={ref}
            styles={selectStyles}
            components={currentComponents}
            theme={currentTheme}
            menuPortalTarget={portalTarget}
            {...props}
        />
    );
};
