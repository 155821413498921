import { FormError } from 'components/atoms/FormError';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ShipmentInput } from '@magicship/utils/shipment';
import { UnitSelect } from './UnitSelect';
import { LengthUnit } from 'src/API';

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    ${Input} {
        width: 100%;
        max-width: 72px;
        flex-grow: 1;
    }
`;

const Separator = styled.span`
    margin: 0 5px;
`;

type Props = {
    index: number;
    contrast?: boolean;
};

const getValidationMessage = (error: FieldError | undefined, positiveError: string) =>
    error?.message || (error ? positiveError : '');

export const DimensionsField: React.FunctionComponent<Props> = ({ index, contrast = true }: Props) => {
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<ShipmentInput>();
    const { t } = useTranslation(['shipping', 'common']);

    const dimensionsError = errors?.boxes?.[index]?.dimensions;
    const lengthError = getValidationMessage(dimensionsError?.length, t<string>('Length is required'));
    const widthError = getValidationMessage(dimensionsError?.width, t<string>('Width is required'));
    const heightError = getValidationMessage(dimensionsError?.height, t<string>('Height is required'));
    const unitError = getValidationMessage(dimensionsError?.unit, t<string>('Measurement unit is required'));

    const fieldError = lengthError || widthError || heightError || unitError;
    const bg = contrast ? 'contrast' : 'default';
    return (
        <div>
            <Container>
                <Input
                    placeholder="L"
                    className="data-hj-allow"
                    bg={bg}
                    error={Boolean(lengthError)}
                    type="number"
                    min={0}
                    step={0.1}
                    {...register(`boxes.${index}.dimensions.length` as `boxes.0.dimensions.length`, {
                        required: true,
                        valueAsNumber: true,
                        validate: {
                            positive: (value) => parseFloat(value) > 0,
                        },
                    })}
                />
                <Separator>X</Separator>
                <Input
                    className="data-hj-allow"
                    placeholder="W"
                    bg={bg}
                    error={Boolean(widthError)}
                    type="number"
                    min={0}
                    step={0.1}
                    {...register(`boxes.${index}.dimensions.width` as `boxes.0.dimensions.width`, {
                        required: true,
                        valueAsNumber: true,
                        validate: {
                            positive: (value) => parseFloat(value) > 0,
                        },
                    })}
                />
                <Separator>X</Separator>
                <Input
                    placeholder="H"
                    className="data-hj-allow"
                    bg={bg}
                    error={Boolean(heightError)}
                    type="number"
                    min={0}
                    step={0.1}
                    {...register(`boxes.${index}.dimensions.height` as `boxes.0.dimensions.height`, {
                        required: true,
                        valueAsNumber: true,
                        validate: {
                            positive: (value) => parseFloat(value) > 0,
                        },
                    })}
                />
                <Controller
                    render={({ field }) => (
                        <UnitSelect
                            name={field.name}
                            values={Object.values(LengthUnit)}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                    control={control}
                    name={`boxes.${index}.dimensions.unit`}
                    rules={{ required: t<string>('Measurement unit is required') }}
                />
            </Container>
            {fieldError && <FormError>{fieldError}</FormError>}
        </div>
    );
};
