import cloneImage from 'public/clone.svg';
import styled from 'styled-components';
import Image from 'next/image';
import removeImage from 'public/remove.svg';
import editImage from 'public/edit.svg';

const Container = styled.button`
    background: none;
    border: none;
    appearance: none;
    padding: 0;
    cursor: pointer;

    &:disabled {
        cursor: default;
        filter: grayscale(1);
        opacity: 0.8;
    }
`;

type Icon = 'clone' | 'remove' | 'edit';

type Props = {
    disabled?: boolean;
    icon?: Icon;
    source?: string;
    onClick?: () => void;
};

const getIconSource = (icon: Icon) => {
    switch (icon) {
        case 'clone':
            return cloneImage;
        case 'remove':
            return removeImage;
        case 'edit':
            return editImage;
    }
};

export const IconButton: React.FunctionComponent<Props> = ({ icon, disabled, source, onClick }: Props) => {
    return (
        <Container type="button" disabled={disabled} onClick={onClick}>
            {icon && <Image src={source || (icon ? getIconSource(icon) : '')} alt={icon} width="20" height="20" />}
            {source && <Image src={source} alt="remove" />}
        </Container>
    );
};
