import { LengthUnit, PackageType, ShippingAddressInput, WeightUnit, OrderItem } from '~graphql/API';

export let boxId = 0;

export const makeBoxInput = (lengthUnit?: LengthUnit, weightUnit?: WeightUnit): BoxInput => {
    return {
        id: boxId++,
        dimensions: {
            length: '',
            width: '',
            height: '',
            unit: lengthUnit,
        },
        weight: {
            value: '',
            unit: weightUnit,
        },
    };
};

export type DimensionsInput = {
    length: string;
    width: string;
    height: string;
    unit: LengthUnit | undefined;
};

export type WeightInput = {
    value: string;
    unit: WeightUnit | undefined;
};

export type BoxInput = {
    id: number;
    dimensions: DimensionsInput;
    weight: WeightInput;
};

export type ShipmentInput = {
    from: ShippingAddressInput;
    to: ShippingAddressInput;
    packageType: PackageType;
    boxes: BoxInput[];
};

export const initialState: ShipmentInput = {
    packageType: PackageType.box,
    from: {
        name: '',
        attentionName: '',
        email: '',
        phone: '',
        postalCode: '',
        countryCode: 'CA',
        stateCode: '',
        address1: '',
        address2: '',
        city: '',
        commercial: false,
    },
    to: {
        name: '',
        attentionName: '',
        email: '',
        phone: '',
        postalCode: '',
        countryCode: '',
        stateCode: '',
        address1: '',
        address2: '',
        city: '',
        commercial: false,
    },
    boxes: [makeBoxInput()],
};

export const MAX_SHIPPING_DURATION = 60000;
export const MAX_SYNC_DURATION = 60000 * 5;
export const MAX_TOGGLE_DURATION = 60000;

const timeStampReference = (maxDuration: number, timestamp?: number) => {
    return (timestamp ?? new Date().getTime()) - maxDuration;
}

export const shippingTimestampReference = (timestamp?: number) => {
    return timeStampReference(MAX_SHIPPING_DURATION, timestamp);
}

export const syncTimestampReference = (timestamp?: number) => {
    return timeStampReference(MAX_SYNC_DURATION, timestamp);
}

export const toggleTimestampReference = (timestamp?: number) => {
    return timeStampReference(MAX_TOGGLE_DURATION, timestamp);
}

export const sanitizePostalCode = (postalCode: string) => {
    return postalCode.replace(/\s+/g, '').toUpperCase();
}

export const getLastOrderPackageId = (storeId: string, items: OrderItem[], useProductId = false) => {
    const property: keyof OrderItem = useProductId ? 'productId' : 'variantId';
    const hasUndefined = items.some(item => !item || !item[property]);
    if (hasUndefined) {
        return null;
    }
    return `${storeId}-${items.map((i) => `${i[property]}*${i.quantity}`).join('+')}`;
}
