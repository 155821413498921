import React from 'react';
import styled from 'styled-components';
import { RadioInput } from 'components/atoms/RadioInput';

type Props<T extends string> = {
    name: string;
    values: readonly T[];
    value?: T;
    onChange: (value: T) => void;
    className?: string;
    style?: React.CSSProperties;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
`;

export const UnitSelect = <T extends string>({
    className,
    style,
    values,
    name,
    onChange,
    value,
}: Props<T>): React.ReactElement => {
    return (
        <Container className={className} style={style}>
            {values.map((x) => (
                <RadioInput<T>
                    className="data-hj-allow"
                    key={x}
                    name={name}
                    isSelected={x === value}
                    onChange={onChange}
                    value={x}
                    label={x.toUpperCase()}
                />
            ))}
        </Container>
    );
};
